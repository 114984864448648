<template>
  <app-layout>
    <div class="container mx-auto h-full flex flex-col md:flex-row w-full">
      <div class="md:px-5 py-2 md:py-10 lg:w-1/5 px-5">
        <app-button-menu
          class="
            mb-3
            md:mb-5 md:p-0
            w-full
            md:w-auto md:mx-0
            text-xl
            font-semibold
            bg-white
            flex
            items-center
            md:hover:bg-white
            justify-between
          "
          @onMenuClick="toggleMenu"
        >
          <p class="text-black">
            {{ $t("integration.menu.title") }}
          </p>
          <app-icon
            name="ChevronRightIcon"
            :class="[
              'h-6 w-6 text-black md:hidden transform',
              showMenu ? 'rotate-90' : 'rotate-0',
            ]"
          />
        </app-button-menu>
        <app-button-menu
          :class="[
            'w-full space-x-3 items-center font-medium border-l-2 md:flex transition-all',
            showMenu ? 'flex' : 'hidden',
          ]"
          v-for="menu in menu_items"
          v-bind:key="menu.title"
          :rounded="false"
          :fullWidth="true"
          @onMenuClick="onMenuClick(menu)"
          :active="isCurrentPath(menu.path)"
          :disabled="menu.disabled"
        >
          <p
            :class="[
              'text-left h-5 truncate ...',
              isCurrentPath(menu.path) ? 'text-primary' : 'text-black',
            ]"
          >
            {{ menu.title }}
          </p>
        </app-button-menu>
      </div>
      <div class="md:w-4/5 row-span-5">
        <router-view />
      </div>
    </div>
  </app-layout>
</template>

<script>
export default {
  data() {
    return {
      menu_items: [
        {
          id: 1,
          title: this.$t("integration.menu.api"),
          path: "/integration/api",
          disabled: false,
        },
        {
          id: 2,
          title: this.$t("integration.menu.webhook"),
          path: "/integration/webhook",
          disabled: false,
        },
      ],
      showMenu: false,
    };
  },

  computed: {
    integration() {
      return this.$store.getters["integrationStore/integration"];
    },
    hasRoleMasterBusiness() {
      return this.$store.getters["authStore/hasRoleMasterBusiness"]()
    },
  },

  mounted() {
    // check if not have role master business
    if(!this.hasRoleMasterBusiness){
      this.$store.dispatch("integrationStore/retrieveIntegration");
    }
  },

  methods: {
    onMenuClick(menu) {
      this.$router.push({ path: menu.path });
    },

    isCurrentPath(path) {
      return this.$route.path?.startsWith(path)
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
